import request from '@/utils/request';
import Vue from 'vue';

export function QrCode(data) {
  return request({
    url: '/invite/code?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
