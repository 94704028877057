<template>
  <div class="inviteView">
    <div class="nav-fixed">
      <van-nav-bar left-arrow @click-left="onClickLeft" />
    </div>
    <div class="hz_form">
      <div class="buy_fabu_mod" :style="{ backgroundImage: 'url(' + bgimage + ')' }">
        <div class="checkin_notice">
          <div class="checkin_notice_header">
            <h4 v-html="$t('您的好友') + ' ' + this.$store.getters.userinfo.mobile"></h4>
            <h4 v-html="$t('邀请您加入') + ' ' + this.$store.getters.userinfo.web_name"></h4>
            <van-image :src="detail.qr_pic ? $imageUrl + detail.qr_pic : ''" v-if="detail.qr_pic" />
            <h4 v-html="$t('推荐码') + '：' + this.$store.getters.userinfo.inviteCode"></h4>
            <div class="PromoteInfo" style="margin-bottom: 0.4rem;">
              <van-icon name="description" @click="doCopyCode" />
              {{ $t('复制推荐码') }}
            </div>
            <h4 v-html="this.$store.getters.userinfo.qr_link"></h4>
            <div class="PromoteInfo">
              <van-icon name="description" @click="doCopyUrl" />
              {{ $t('复制邀请链接') }}
            </div>
          </div>
          <div class="btn">
            <van-button @click="downQrcode" plain class="big_btn2">{{ $t('保存二维码') }}</van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QrCode } from '@/api/invite';
export default {
  data() {
    return {
      id: this.$route.query.id,
      detail: {},
      bgimage: require('../../assets/images/share-' + this.$lang + '.png'),
    };
  },
  created() {
    this.getQrCode();
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    }, //获取公告详情
    getQrCode() {
      QrCode({ id: this.id })
        .then((response) => {
          this.detail = response.result;
        })
        .catch((error) => {});
    },
    downQrcode() {
      this.getUrlBase64(this.$imageUrl + this.detail.qr_pic).then((base64) => {
        let a = document.createElement('a');
        a.style.display = 'none';
        a.download = this.name;
        a.href = base64;
        document.body.appendChild(a);
        a.click();
      });
    },
    getUrlBase64(url) {
      return new Promise((resolve) => {
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        let img = new Image();
        img.crossOrigin = 'Anonymous'; //允许跨域
        img.src = url;
        img.onload = function() {
          canvas.height = 300;
          canvas.width = 300;
          ctx.drawImage(img, 0, 0, 300, 300);
          let dataURL = canvas.toDataURL('image/png');
          canvas = null;
          resolve(dataURL);
        };
      });
    },
    doCopyCode: function() {
      this.$copyText(this.$store.getters.userinfo.inviteCode).then(
        (e) => {
          this.$toast({
            message: this.$t('复制成功'),
            duration: 500,
            forbidClick: true,
          });
        },
        (e) => {
          this.$toast({
            message: this.$t('复制失败'),
            duration: 500,
            forbidClick: true,
          });
        }
      );
    },
    doCopyUrl: function() {
      this.$copyText(this.detail.qr_link).then(
        (e) => {
          this.$toast({
            message: this.$t('复制成功'),
            duration: 500,
            forbidClick: true,
          });
        },
        (e) => {
          this.$toast({
            message: this.$t('复制失败'),
            duration: 500,
            forbidClick: true,
          });
        }
      );
    },
    registerHandle() {
      this.$router.push('/main/user/invite/register');
    },
  },
};
</script>
<style lang="stylus">
.inviteView
  .van-nav-bar
    background: transparent
  .hz_form
    .buy_fabu_mod
      overflow: hidden
      background-repeat: no-repeat
      background-size: contain
      height: 100vh
      background-size: contain
      padding: 0 1.225rem 0
      background-color: #09273f
      .checkin_notice
        padding-top: 4.1rem
        .checkin_notice_header
          font-size: 0.42rem
          text-align: center
          line-height: 0.42rem
          margin-bottom: 0.2rem
          font-weight: 700
          background: #12324b
          padding: 0.5rem 0.3rem
          h4
            color: #ffffff
            font-weight: normal
            font-size: 0.34rem
            margin-bottom: 0.34rem
            margin-top: 0
          .PromoteInfo
            color: #1a6ba1
            display: inline-block
            font-weight: normal
            font-size: 0.36rem
            display: flex
            align-items: center
            justify-content: center
            .van-icon
              margin-right: 0.1rem
              vertical-align: middle
              font-size: 0.5rem
    .btn
      width: 100%
      margin: 0.5rem auto
</style>
